// @flow strict

import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

import * as moment from 'moment';

import MaybeAvatar from './MaybeAvatar';
import Interaction from './Interaction';

import type { Story } from './API';

export type StoriesProps = {
  stories: Array<Story>,
  loading: boolean,
  ended: boolean,
  onLoadMore: () => any,
};

const useStyles = makeStyles((theme) => ({
  listItemContainer: {
    '& .MuiListItem-secondaryAction': {
      paddingRight: 48 * 4,
    },
  },
  [theme.breakpoints.down('sm')]: {
    listItemContainer: {
      '& .MuiListItem-secondaryAction': {
        paddingBottom: 0,
        paddingRight: theme.spacing(2),
      },
    },
    listItemSecondaryAction: {
      paddingBottom: theme.spacing(1),
      paddingLeft: 40 + theme.spacing(2),
      paddingRogjt: theme.spacing(2),
      position: 'static',
      transform: 'translateY(0)',
    },
  },
  loadingTrigger: {
    alignItems: 'center',
    flexDirection: 'column',
  },

  thumbUpSet: {
    color: theme.palette.primary.main,
  },
  thumbDownSet: {
    color: theme.palette.secondary.main,
  },
  bookmarkSet: {
    color: theme.palette.primary.main,
  },
  boldText: {
    fontWeight: 'bold',
  },
  tickerAnchor: {
    color: 'blue',
    fontWeight: 'bold',
  },
}));

function Stories(props: StoriesProps) {
  const classes = useStyles();
  const [loadingTriggerNode, setLoadingTriggerNode] = useState();
  const loadingTriggerObserver = useRef(null);
  const [interactionCounter, setInteractionCounter] = useState(0);

  const onLoadMore = props.onLoadMore;

  const url = new URL(window.location.href);

  let getStorySecondaryInfo = (story) => {
    const storyMom = moment(story.time);
    let ago = '';
    // Note that 'storyMom.startOf()' will modify 'storyMom' itself.
    if (
      moment(Date.now())
        .startOf('day')
        .valueOf() < storyMom.valueOf()
    ) {
      ago = 'Today';
    } else {
      ago = storyMom.fromNow();
    }
    ago = ago[0].toUpperCase() + ago.slice(1);

    return (
      <span>
        <span className={classes.boldText}> {ago} </span> (
        {storyMom.format('MMM. DD - YYYY')}) - {story.site}
        {// Tags are actually tickers
        story.tags
          ? story.tags.map((ticker: string, index) => {
              url.searchParams.set('q', ticker);
              return (
                <>
                  {'  '}
                  <Link
                    href={url.href}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.tickerAnchor}
                  >
                    {ticker}
                  </Link>
                </>
              );
            })
          : null}
      </span>
    );
  };

  useEffect(() => {
    if (!window.IntersectionObserver) {
      return;
    }

    if (loadingTriggerObserver.current) {
      loadingTriggerObserver.current.disconnect();
    }

    if (loadingTriggerNode) {
      loadingTriggerObserver.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && onLoadMore) {
              onLoadMore();
            }
          });
        },
        {
          threshold: 1.0,
        },
      );
      loadingTriggerObserver.current.observe(loadingTriggerNode);
    }

    return () => {
      if (loadingTriggerObserver.current) {
        loadingTriggerObserver.current.disconnect();
      }
    };
  }, [loadingTriggerNode, onLoadMore]);

  let getPoweredByItem = () => {
    return (
      <>
        <ListItem className={classes.loadingTrigger}>
          <ListItemText
            primary={
              <Link
                href="https://github.com/hczhu/TickerTick-API"
                target="_blank"
              >
                Keep scrolling to load more stories. Powered by TickerTick API.
              </Link>
            }
          ></ListItemText>
        </ListItem>
        <Divider variant="middle" component="li" />
      </>
    );
  };
  return (
    <List>
      {props.stories.map((story: Story, index) => {
        if (
          (Interaction.getPreviousDislike(story.id) ||
            Interaction.getView(story.id)) &&
          !Interaction.getSave(story.id)
        ) {
          return null;
        }
        return (
          <>
            {index > 0 ? <Divider variant="middle" component="li" /> : null}
            <ListItem
              key={story.id}
              ContainerProps={{ className: classes.listItemContainer }}
              alignItems="flex-start"
            >
              <ListItemAvatar>
                <MaybeAvatar
                  variant="rounded"
                  alt={story.site}
                  title={story.site}
                  src={
                    story.favicon_url
                      ? story.favicon_url
                      : `no-such-favicon.ico`
                  }
                >
                  {story.site
                    .substr(story.site.search(/[^.]*\.[^.]*$/), 1)
                    .toUpperCase()}
                </MaybeAvatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Link
                    href={story.url}
                    onClick={() => {
                      Interaction.setClick(story.id);
                    }}
                    target="_blank"
                    rel="noreferrer"
                    sid={story.id}
                  >
                    {story.title}
                  </Link>
                }
                secondary={getStorySecondaryInfo(story)}
              ></ListItemText>
              <ListItemSecondaryAction
                className={classes.listItemSecondaryAction}
              >
                <Tooltip title="Dislike">
                  <IconButton
                    onClick={() => {
                      Interaction.getDislike(story.id)
                        ? Interaction.unsetDislike(story.id)
                        : Interaction.setDislike(story.id);
                      setInteractionCounter(interactionCounter + 1);
                    }}
                  >
                    <ThumbDownIcon
                      className={
                        Interaction.getDislike(story.id)
                          ? classes.thumbDownSet
                          : null
                      }
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Save">
                  <IconButton
                    onClick={() => {
                      Interaction.getSave(story.id)
                        ? Interaction.unsetSave(story.id)
                        : Interaction.setSave(story.id);
                      setInteractionCounter(interactionCounter + 1);
                    }}
                  >
                    {Interaction.getSave(story.id) ? (
                      <BookmarkIcon className={classes.bookmarkSet} />
                    ) : (
                      <BookmarkBorderIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          </>
        );
      })}
      {props.ended ? null : (
        <ListItem
          ref={setLoadingTriggerNode}
          className={classes.loadingTrigger}
        >
          {props.loading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<ExpandMoreIcon />}
              onClick={() => {
                if (onLoadMore) {
                  onLoadMore();
                }
              }}
            >
              Load More
            </Button>
          )}
        </ListItem>
      )}
    </List>
  );
}

export default Stories;
