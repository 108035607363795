// @flow strict

const tickerPool = new Map();

class Ticker {
  ticker: string;
  companyName: ?string;
  chineseName: ?string;

  constructor(ticker: string, companyName?: string) {
    const canonicalTicker = ticker.toUpperCase();
    const sameTicker = tickerPool.get(canonicalTicker);

    if (sameTicker) {
      if (!sameTicker.companyName) {
        sameTicker.companyName = companyName;
      }
      return sameTicker;
    }

    tickerPool.set(canonicalTicker, this);
    this.ticker = canonicalTicker;
    this.companyName = companyName;
  }
}

export default Ticker;
