// @flow strict

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import { makeStyles } from '@material-ui/core/styles';

import API, {
  LANGUAGES,
  BROWSER_LANGUAGES,
  RELEVANCE,
  EXCLUSIONS,
} from './API';
import Filters from './Filters';
import LanguagesFilter from './LanguagesFilter';
import ExclusionFilter from './ExclusionFilter';
import Search from './Search';
import Stories from './Stories';
import Interaction from './Interaction';

import type { Story } from './API';

import type { LanguageCode, ExclusionCode } from './API';

export type FeedProps = {};

const useStyles = makeStyles((theme) => ({
  footer: {
    top: 'auto',
    bottom: 0,
  },
}));

function createQuery(tickers): string | void {
  if (tickers.length === 0) {
    return undefined;
  }
  const unique_tickers = Array.from(new Set(tickers));
  const tickers_str = unique_tickers.map((ticker) => `tt:${ticker}`).join(' ');

  return `(or ${tickers_str})`;
}

const lastFetchedStoryIDs: Array<string> = [];
const tickertickSiteRegex=new RegExp("^https://.*tickertick\.com/");

function updateFetchedStories(stories: Array<Story>) {
  for (var k = 0; k < lastFetchedStoryIDs.length; ++k) {
    Interaction.appendNewStory(lastFetchedStoryIDs[k]);
  }
  while (lastFetchedStoryIDs.length > 0) {
    lastFetchedStoryIDs.pop();
  }
  for (var k = 0; k < stories.length; ++k) {
    lastFetchedStoryIDs.push(stories[k].id);

    if (stories[k].favicon_url && !stories[k].favicon_url.match(tickertickSiteRegex)) {
        delete stories[k].favicon_url;
    }
  }
}

function Feed(props: FeedProps) {
  const location = useLocation();
  const [tickers, setTickers] = useState<Array<string>>([]);
  const [languages, setLanguages] = useState<Array<LanguageCode>>([]);
  const [exclusions, setExclusions] = useState<Array<ExclusionCode>>([]);
  const [loading, setLoading] = useState(false);
  const [ended, setEnded] = useState(false);
  const [stories, setStories] = useState<Array<Story>>([]);

  const classes = useStyles();

  useEffect(() => {
    const url = new URL(window.location.href);
    url.search = location.search;

    const query = url.searchParams.get('q');
    if (query) {
      setTickers(query.split(','));
    }

    const language = url.searchParams.get('lang');
    if (language) {
      const languages = language.split(',');
      setLanguages(
        Object.keys(LANGUAGES).filter(
          (language) => languages.indexOf(language) > -1,
        ),
      );
    } else {
      setLanguages(BROWSER_LANGUAGES);
    }

    const exclusion = url.searchParams.get('excl');
    if (exclusion) {
      const exclusions = exclusion.split(',');
      setExclusions(
        Object.keys(EXCLUSIONS).filter(
          (exclusion) => exclusions.indexOf(exclusion) > -1,
        ),
      );
    } else {
      setExclusions([]);
    }

    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault();
      return Interaction.sync();
    });
  }, [location]);

  useEffect(() => {
    let obsolete = false;

    (async () => {
      setEnded(false);
      setLoading(true);
      try {
        const result = await API.feed(
          createQuery(tickers),
          exclusions,
          languages,
        );
        updateFetchedStories(result.stories);
        if (!obsolete) {
          setStories(result.stories);
        }
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      obsolete = true;
    };
  }, [tickers, languages, exclusions]);

  const loadMore = async () => {
    if (loading) {
      // don't trigger new loading when it's already loading something
      return;
    }

    setLoading(true);
    try {
      const result = await API.feed(
        createQuery(tickers),
        exclusions,
        languages,
        undefined,
        stories.length > 0 ? stories[stories.length - 1].id : undefined,
      );
      if (result.stories.length === 0) {
        setEnded(true);
      } else {
        updateFetchedStories(result.stories);
        setStories(stories.concat(result.stories));
      }
    } finally {
      setLoading(false);
    }
  };

  const kScrollToTopValue = 'scroll_to_top';
  const kMarkAsRead = 'mark_as_read';
  return (
    <>
      <AppBar position="static">
        <Search
          onTickersChange={(tickers) => {
            setTickers(tickers);
          }}
        />
        <Filters>
          <LanguagesFilter
            onLanguagesChange={(languages) => {
              setLanguages(languages);
            }}
          />

          <ExclusionFilter
            onExclusionChange={(exclusions) => {
              setExclusions(exclusions);
            }}
          />
        </Filters>
      </AppBar>
      <Stories
        stories={stories}
        loading={loading}
        ended={ended}
        onLoadMore={loadMore}
      />
      <AppBar position="fixed" className={classes.footer}>
        <BottomNavigation
          showLabels
          position="fixed"
          onChange={(event, newValue) => {
            if (newValue == kScrollToTopValue) {
              window.scroll(0, 0);
            } else if (newValue == kMarkAsRead) {
              Interaction.markAllViewed();
            }
          }}
        >
          <BottomNavigationAction
            label="Scroll to top"
            value={kScrollToTopValue}
            icon={<ArrowUpwardIcon color="action" />}
          />
          <BottomNavigationAction
            label="Mark as read"
            value={kMarkAsRead}
            icon={<CheckCircleRoundedIcon color="action" />}
          />
        </BottomNavigation>
      </AppBar>
    </>
  );
}

export default Feed;
