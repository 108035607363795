// @flow strict

import React, { useEffect, useState } from 'react';

import Avatar from '@material-ui/core/Avatar';

import type { ElementConfig } from 'react';

const knownErrors = {};

function MaybeAvatar(props: ElementConfig<typeof Avatar>) {
  const [avatarProps, setAvatarProps] = useState(props);

  useEffect(() => {
    const imgProps = props.imgProps || {};
    const onError = imgProps.onError || (() => {});
    setAvatarProps({
      ...props,
      src: knownErrors[props.src] ? null : props.src,
      imgProps: {
        ...imgProps,
        onError: (event) => {
          knownErrors[props.src] = true;
          setAvatarProps({
            ...props,
            src: null,
          });
          onError(event);
        },
      },
    });
  }, [props]);

  return <Avatar {...avatarProps} />;
}

export default MaybeAvatar;
