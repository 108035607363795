// @flow strict

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import type { Node } from 'react';

const useStyles = makeStyles((theme) => ({
  panel: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: '0',
    },
  },
  summary: {
    '&.Mui-expanded': {
      minHeight: '48px',
    },
    '& .MuiExpansionPanelSummary-content': {
      '&.Mui-expanded': {
        margin: '12px 0',
      },
    },
  },
  details: {
    paddingBottom: 0,
    marginLeft: -theme.spacing(1),
    marginRight: -theme.spacing(1),
    '& > .MuiFormControl-root': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
}));

function Filters(props: { children?: Node }) {
  const classes = useStyles();

  return (
    <ExpansionPanel square={true} className={classes.panel}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon className={classes.icon} />}
        className={classes.summary}
      >
        Filters
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        {props.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default Filters;
