// @flow strict

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { fade, makeStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import API from './API';
import Ticker from './Ticker';

export const TICKERS_STORAGE_KEY = '__latest-tickers';

export type SearchProps = {
  onTickersChange: (Array<string>) => any,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },
  autoComplete: {
    '& .MuiAutocomplete-tag': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '& .MuiChip-deleteIcon': {
        color: fade(theme.palette.common.white, 0.25),
      },
      '& .MuiChip-deleteIcon:hover': {
        color: fade(theme.palette.common.white, 0.35),
      },
    },
    '& .MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator': {
      color: theme.palette.common.white,
    },
    '& .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator': {
      color: theme.palette.common.white,
    },
  },
  textField: {
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    borderColor: theme.palette.common.white,
    margin: theme.spacing(1),
    '& .MuiInput-underline::before': {
      border: 'none',
    },
    '& .MuiInput-underline:hover::before': {
      border: 'none',
    },
    '& .MuiInput-underline::after': {
      border: 'none',
    },
  },
  input: {
    color: theme.palette.common.white,
    '& input': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  option: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

function writeTickersToLocalStorage(tickers) {
  if (tickers == '' || tickers == null) {
    return;
  }
  try {
    localStorage.setItem(TICKERS_STORAGE_KEY, tickers);
  } catch (e) {}
}

function Search(props: SearchProps) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [ticker, setTicker] = useState('');
  const [tickers, setTickers] = useState<Array<Ticker>>([]);
  const [options, setOptions] = useState<Array<Ticker>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    url.search = location.search;
    const query = url.searchParams.get('q');
    if (query) {
      setTickers(query.split(',').map((ticker) => new Ticker(ticker)));
      writeTickersToLocalStorage(query);
    }
  }, [location]);

  useEffect(() => {
    let obsolete = false;

    (async () => {
      setLoading(true);
      try {
        const result = await API.search(ticker);
        if (!obsolete) {
          setOptions(result);
        }
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      obsolete = true;
    };
  }, [ticker]);

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <Autocomplete
        // Automatically highlight/select the first option.
        autoHighlight={true}
        multiple={true}
        filterSelectedOptions={true}
        loading={loading}
        className={classes.autoComplete}
        value={tickers}
        options={options}
        filterOptions={(options: Array<Ticker>) => {
          // override Autocomplete's built-in filter function that's based on
          // string matching of stringified JSON
          return options;
        }}
        getOptionLabel={(option: Ticker) => {
          return option.ticker.toLowerCase();
        }}
        renderOption={(option: Ticker) => {
          return (
            <div className={classes.option}>
              <Typography component="div" variant="h5">
                {option.companyName} {option.chineseName}
              </Typography>
              <Typography component="div">
                {option.ticker.toLowerCase()}
              </Typography>
            </div>
          );
        }}
        onInputChange={(event, value: string) => {
          setTicker(value);
        }}
        renderInput={(params) => (
          <div className={classes.container}>
            <TextField
              {...params}
              placeholder="Type a ticker or a company name"
              type="search"
              margin="normal"
              variant="standard"
              className={classes.textField}
              InputProps={{
                ...params.InputProps,
                className: [
                  params.InputProps.className, // necessary for multi-line support
                  classes.input,
                ],
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              fullWidth={true}
            />
          </div>
        )}
        onChange={(event, value: Array<Ticker>) => {
          const selectedTickers = value.map((ticker) =>
            ticker.ticker.toLowerCase(),
          );
          setTickers(value);

          const url = new URL(window.location.href);
          url.search = location.search;
          url.searchParams.set('q', selectedTickers.join(','));
          history.push(`/?${url.searchParams.toString()}`);

          if (props.onTickersChange) {
            props.onTickersChange(selectedTickers);
          }
        }}
      />
    </form>
  );
}

export default Search;
