// @flow strict

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { BROWSER_LANGUAGES, LANGUAGES } from './API';

import type { LanguageCode } from './API';

export type LanguagesFilterProps = {
  onLanguagesChange: (Array<LanguageCode>) => any,
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.contrastText,
  },
  select: {
    color: theme.palette.primary.contrastText,
    '& .MuiSelect-icon': {
      color: theme.palette.primary.contrastText,
    },
    '&.MuiInput-underline::before': {
      borderColor: theme.palette.common.white,
    },
  },
}));

function LanguagesFilter(props: LanguagesFilterProps) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [selectedLanguageCodes, setSelectedLanguageCodes] = useState<
    Array<LanguageCode>,
  >([]);

  useEffect(() => {
    const url = new URL(window.location.href);
    url.search = location.search;
    const language = url.searchParams.get('lang');
    if (language) {
      const languages = language.split(',');
      setSelectedLanguageCodes(
        Object.keys(LANGUAGES).filter(
          (language) => languages.indexOf(language) > -1,
        ),
      );
    } else {
      setSelectedLanguageCodes(BROWSER_LANGUAGES);
    }
  }, [location]);

  return (
    <FormControl>
      <InputLabel id="languagesFilterLabel" className={classes.label}>
        Languages
      </InputLabel>
      <Select
        labelId="languagesFilterLabel"
        className={classes.select}
        multiple={true}
        value={selectedLanguageCodes}
        onChange={(event) => {
          const value = event.target.value;
          setSelectedLanguageCodes(value);

          const url = new URL(window.location.href);
          url.search = location.search;
          url.searchParams.set('lang', value.join(','));
          history.push(`/?${url.searchParams.toString()}`);

          if (props.onLanguagesChange) {
            props.onLanguagesChange(value);
          }
        }}
        input={<Input />}
        renderValue={(selected) =>
          selected.map((languageCode) => LANGUAGES[languageCode]).join(', ')
        }
      >
        {Object.keys(LANGUAGES).map((languageCode) => (
          <MenuItem key={languageCode} value={languageCode}>
            <Checkbox
              checked={selectedLanguageCodes.indexOf(languageCode) > -1}
            />
            <ListItemText primary={LANGUAGES[languageCode]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default LanguagesFilter;
