// @flow strict

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { EXCLUSIONS } from './API';

import type { ExclusionCode } from './API';

export type ExclusionFilterProps = {
  onExclusionChange: (Array<ExclusionCode>) => any,
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.contrastText,
  },
  select: {
    color: theme.palette.primary.contrastText,
    '& .MuiSelect-icon': {
      color: theme.palette.primary.contrastText,
    },
    '&.MuiInput-underline::before': {
      borderColor: theme.palette.common.white,
    },
  },
}));

function ExclusionFilter(props: ExclusionFilterProps) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [selectedExclusionCodes, setSelectedExclusionCodes] = useState<
    Array<ExclusionCode>,
  >([]);

  useEffect(() => {
    const url = new URL(window.location.href);
    url.search = location.search;
    const exclusion = url.searchParams.get('excl');
    if (exclusion) {
      const exclusions = exclusion.split(',');
      setSelectedExclusionCodes(
        Object.keys(EXCLUSIONS).filter(
          (exclusion) => exclusions.indexOf(exclusion) > -1,
        ),
      );
    } else {
      setSelectedExclusionCodes([]);
    }
  }, [location]);

  return (
    <FormControl>
      <InputLabel id="exclusionFilterLabel" className={classes.label}>
        Exclusion
      </InputLabel>
      <Select
        labelId="exclusionFilterLabel"
        className={classes.select}
        multiple={true}
        value={selectedExclusionCodes}
        onChange={(event) => {
          const value = event.target.value;
          setSelectedExclusionCodes(value);

          const url = new URL(window.location.href);
          url.search = location.search;
          url.searchParams.set('excl', value.join(','));
          history.push(`/?${url.searchParams.toString()}`);

          if (props.onExclusionChange) {
            props.onExclusionChange(value);
          }
        }}
        input={<Input />}
        renderValue={(selected) =>
          selected.map((exclusionCode) => EXCLUSIONS[exclusionCode]).join(', ')
        }
      >
        {Object.keys(EXCLUSIONS).map((exclusionCode) => (
          <MenuItem key={exclusionCode} value={exclusionCode}>
            <Checkbox
              checked={selectedExclusionCodes.indexOf(exclusionCode) > -1}
            />
            <ListItemText primary={EXCLUSIONS[exclusionCode]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ExclusionFilter;
