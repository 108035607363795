// @flow strict

import Storage from 'versioned-storage';

import API from './API';

const STORY_STATE_BIT_MASK = {
  // If the bit is 1, the story has the state.
  VIEWED: 1,
  CLICKED: 2,
  DISLIKED: 4,
  SAVED: 8,
};
export type StoryStateBitMask = $Values<typeof STORY_STATE_BIT_MASK>;

const MAX_NUM_STORIES_IN_CACHE = 1000;
const ACTIONS_BATCH_SIZE = 1;
const STORAGE_NAME = 'interaction';
const STORAGE_VERSION = 5;

class StoryFeedbackBatch {
  // All strings are story IDs.
  dislike: Array<string>;
  undislike: Array<string>;
  save: Array<string>;
  unsave: Array<string>;
  click: Array<string>;
  view: Array<string>;
}

class Cache {
  storyStateBits: { [storyID: string]: number };
  // Used to prune the map above according to FIFO strategy.
  storyIdQueue: Array<string>;

  // Actions which need to be sent to the backend.
  // TODO: prune unsynced actions if there are too many.
  unsyncedActions: { [actionName: string]: Array<string> };

  constructor(data: Object) {
    this.storyStateBits = {};
    this.storyIdQueue = [];
    this.unsyncedActions = {};

    if (data) {
      this.storyStateBits = data.storyStateBits;
      this.storyIdQueue = data.storyIdQueue;
      this.unsyncedActions = data.unsyncedActions;
    }
  }

  prune(): void {
    while (
      Object.keys(this.storyStateBits).length > MAX_NUM_STORIES_IN_CACHE ||
      this.storyIdQueue.length > MAX_NUM_STORIES_IN_CACHE
    ) {
      if (this.storyIdQueue.length == 0) {
        break;
      }
      const story = this.storyIdQueue[0];
      this.storyIdQueue.shift();
      delete this.storyStateBits[story];
    }
  }

  numUnsyncedActions(): number {
    let res = 0;
    for (var k of Object.keys(this.unsyncedActions)) {
      res += this.unsyncedActions[k].length;
    }
    return res;
  }

  maybeTriggerStateChange(
    storyID: string,
    stateBitMask: StoryStateBitMask,
    targetValue: number,
    actionName: string,
  ): void {
    const currentBits = this.storyStateBits[storyID] || 0;
    if ((currentBits & stateBitMask) != targetValue) {
      if (!this.unsyncedActions[actionName]) {
        this.unsyncedActions[actionName] = [];
      }
      this.unsyncedActions[actionName].push(storyID);
      this.storyStateBits[storyID] = currentBits ^ stateBitMask;
      this.storyIdQueue.push(storyID);
      this.prune();
    }
  }

  async syncToBackend(): Promise<void> {
    const unsyncedActions = this.unsyncedActions;
    this.unsyncedActions = {};
    await API.action(JSON.stringify(unsyncedActions)).catch((err) => {
      console.log('Failed to sync story feedback due to: ' + err);
      for (var key of Object.keys(unsyncedActions)) {
        if (!this.unsyncedActions[key]) {
          this.unsyncedActions[key] = [];
        }
        for (var story of unsyncedActions[key]) {
          this.unsyncedActions[key].push(story);
        }
      }
    });
  }

  getStoryStateBit(storyID: string, stateBit: StoryStateBitMask): boolean {
    const bits = this.storyStateBits[storyID] || 0;
    return Boolean(bits & stateBit);
  }

  getDislikedStories(): Set<string> {
    let res = new Set();
    for (var story of Object.keys(this.storyStateBits)) {
      if (this.getStoryStateBit(story, STORY_STATE_BIT_MASK.DISLIKED)) {
        res.add(story);
      }
    }
    return res;
  }
}

class CacheWithLocalStorage {
  storage: Storage<Cache>;
  cache: Cache;

  // The stories here were disliked by this user in prevous http sessions.
  // This one is immutable once it gets initialized from the local storage.
  previouslyDislikedStories: Set<string>;

  constructor() {
    this.storage = new Storage(STORAGE_NAME, STORAGE_VERSION);
    this.cache = new Cache(this.storage.read());
    this.previouslyDislikedStories = this.cache.getDislikedStories();
  }

  getCache(): Cache {
    return this.cache;
  }

  getPreviousDislike(storyID: string): boolean {
    return this.previouslyDislikedStories.has(storyID);
  }

  persist(): void {
    this.storage.write(this.cache);
  }

  async maybeSyncBatch(forceToSync: boolean = false): Promise<void> {
    if (this.cache.numUnsyncedActions() == 0) {
      return;
    }
    if (this.cache.numUnsyncedActions() < ACTIONS_BATCH_SIZE && !forceToSync) {
      return;
    }
    this.persist();
    await this.cache.syncToBackend().then(() => {
      this.persist();
    });
  }
}

const cacheOnLS = new CacheWithLocalStorage();

// All stories in feed without being marked as read/viewed
const currentStoriesIDs: Array<string> = [];

const Interaction = {
  // TODO: call this function when closing the tab/window.
  sync: async (): Promise<void> => {
    await cacheOnLS.maybeSyncBatch(true);
  },

  setClick: (storyID: string) => {
    cacheOnLS
      .getCache()
      .maybeTriggerStateChange(
        storyID,
        STORY_STATE_BIT_MASK.CLICKED,
        1,
        'click',
      );
    cacheOnLS.maybeSyncBatch();
  },

  setView: (storyID: string) => {
    cacheOnLS
      .getCache()
      .maybeTriggerStateChange(storyID, STORY_STATE_BIT_MASK.VIEWED, 1, 'view');
    cacheOnLS.maybeSyncBatch();
  },

  setDislike: (storyID: string) => {
    cacheOnLS
      .getCache()
      .maybeTriggerStateChange(
        storyID,
        STORY_STATE_BIT_MASK.DISLIKED,
        1,
        'dislike',
      );
    cacheOnLS.maybeSyncBatch();
  },
  unsetDislike: (storyID: string) => {
    cacheOnLS
      .getCache()
      .maybeTriggerStateChange(
        storyID,
        STORY_STATE_BIT_MASK.DISLIKED,
        0,
        'undislike',
      );
    cacheOnLS.maybeSyncBatch();
  },

  setSave: (storyID: string) => {
    cacheOnLS
      .getCache()
      .maybeTriggerStateChange(storyID, STORY_STATE_BIT_MASK.SAVED, 1, 'save');
    cacheOnLS.maybeSyncBatch();
  },
  unsetSave: (storyID: string) => {
    cacheOnLS
      .getCache()
      .maybeTriggerStateChange(
        storyID,
        STORY_STATE_BIT_MASK.SAVED,
        0,
        'unsave',
      );
    cacheOnLS.maybeSyncBatch();
  },

  getView: (storyID: string): boolean => {
    return cacheOnLS
      .getCache()
      .getStoryStateBit(storyID, STORY_STATE_BIT_MASK.VIEWED);
  },
  getClick: (storyID: string): boolean => {
    return cacheOnLS
      .getCache()
      .getStoryStateBit(storyID, STORY_STATE_BIT_MASK.CLICKED);
  },
  getDislike: (storyID: string): boolean => {
    return cacheOnLS
      .getCache()
      .getStoryStateBit(storyID, STORY_STATE_BIT_MASK.DISLIKED);
  },
  getPreviousDislike: (storyID: string): boolean => {
    return cacheOnLS.getPreviousDislike(storyID);
  },
  getSave: (storyID: string): boolean => {
    return cacheOnLS
      .getCache()
      .getStoryStateBit(storyID, STORY_STATE_BIT_MASK.SAVED);
  },

  appendNewStory: (storyID: string) => {
    currentStoriesIDs.push(storyID);
  },

  markAllViewed: () => {
    currentStoriesIDs.map((storyID: string, index) => {
      Interaction.setView(storyID);
    });
    while (currentStoriesIDs.length > 0) {
      currentStoriesIDs.pop();
    }
  },
};

export default Interaction;
