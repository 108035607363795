// @flow strict

import React from 'react';
import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import Feed from './Feed';

function App() {
  return (
    <Container fixed>
      <CssBaseline />
      <Paper>
        <Router>
          <Switch>
            <Route exact path="/">
              <Feed />
            </Route>
          </Switch>
        </Router>
      </Paper>
    </Container>
  );
}

export default App;
