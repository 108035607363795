import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'typeface-roboto';
import App from './App';
import Search, { TICKERS_STORAGE_KEY } from './Search';
import * as serviceWorker from './serviceWorker';

const USER_STORAGE_KEY = '__latest-user';

function writeUserToLocalStorage(user) {
  if (user == '' || user == null) {
    return;
  }
  try {
    localStorage.setItem(USER_STORAGE_KEY, user);
  } catch (e) {}
}

function maybeRedirect() {
  const defaultTickers = 'tsla,aapl,fb,goog,amzn';

  const url = new URL(window.location.href);
  let params = url.searchParams;
  let query = params.get('q');
  let shouldRenewParams = false;
  if (query == null || query == '') {
    query = localStorage.getItem(TICKERS_STORAGE_KEY);
    // console.log("query = " + query);
    // console.log("TICKERS_STORAGE_KEY = " + TICKERS_STORAGE_KEY);
    if (query == null || query == '') {
      query = defaultTickers;
    }
    params.set('q', query);
    shouldRenewParams = true;
    // console.log("params = " + params.toString());
  }

  let user = params.get('user');
  if (user == null || user == '') {
    user = localStorage.getItem(USER_STORAGE_KEY);
    if (user != null && user != '') {
      params.set('user', user);
      shouldRenewParams = true;
    }
  } else {
    writeUserToLocalStorage(user);
  }

  if (shouldRenewParams) {
    url.search = params;
    window.location.replace(url.href);
  }
}

maybeRedirect();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
