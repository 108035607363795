// @flow strict

import browserID from 'browser-id';

import Ticker from './Ticker';

const SEARCH_ENDPOINTS: Array<string> = [
  'https://apih.tickertick.com/tickers',
  'https://api1.tickertick.com/tickers',
];
const FEED_ENDPOINTS: Array<string> = [
  'https://apih.tickertick.com/feed',
  'https://api1.tickertick.com/feed',
];

const ACTION_ENDPOINT = 'https://apiw.tickertick.com/story_feedback';

const EMPTY_FEED_JSON = JSON.parse('{ "stories": [] }');

export const LANGUAGES = {
  en: 'English',
  zh: 'Chinese',
};

export type LanguageCode = $Keys<typeof LANGUAGES>;
export type Language = $Values<typeof LANGUAGES>;

export const RELEVANCE = {
  f0: 'Company filings only',
  f1: 'Most relevant stories only',
  f2: 'Include medium relevant stories',
  f3: 'All stories',
};

export type RelevanceLevel = $Keys<typeof RELEVANCE>;
export type Relevance = $Values<typeof RELEVANCE>;

export const EXCLUSIONS = {
  filings: 'Exclude Company Filings',
  ugc: 'Exclude User-Generated Content',
};

export type ExclusionCode = $Keys<typeof EXCLUSIONS>;
export type Exclusion = $Values<typeof EXCLUSIONS>;

export const BROWSER_LANGUAGES = (() => {
  const selectedLanguageCodes = navigator.languages.reduce(
    (result: Array<LanguageCode>, current: string) => {
      const languageCodeCandidate = current.split('-')[0];
      const languageCodeMatch = Object.keys(LANGUAGES).find(
        (languageCode) => languageCode === languageCodeCandidate,
      );
      if (languageCodeMatch && result.indexOf(languageCodeMatch) < 0) {
        result.push(languageCodeMatch);
      }
      return result;
    },
    [],
  );
  return selectedLanguageCodes;
})();

export type Story = {
  id: string,
  title: string,
  url: string,
  site: string,
  time: number,
  favicon_url: string,
  // Tags are actually tickers
  tags: Array<string>,
};

export type FeedResult = {
  stories: Array<Story>,
};

function getUser(thisUrl: URL): string {
  let user = thisUrl.searchParams.get('user');
  if (!user || user == '') {
    user = browserID();
  }
  return user;
}

const API = {
  search: async function(
    query: string,
    limit: number = 10,
  ): Promise<Array<Ticker>> {
    const thisUrl = new URL(window.location.href);
    for (var i = 0; i < SEARCH_ENDPOINTS.length; ++i) {
      const endPoint = SEARCH_ENDPOINTS[i];
      const url = new URL(endPoint);
      url.searchParams.append('p', query);
      url.searchParams.append('n', limit.toString());
      url.searchParams.append('user', getUser(thisUrl));

      try {
        const response = await fetch(url.toString());
        if (response.status != 200) {
          // console.log("Failed to fetch from ", url);
          continue;
        }
        const json = await response.json();

        const result = json.tickers.map((ticker) => {
          let t = new Ticker(ticker.ticker, ticker.company_name);
          if (
            ticker.chinese_name &&
            ticker.company_name.indexOf(ticker.chinese_name) < 0
          ) {
            t.chineseName = ticker.chinese_name;
          }
          return t;
        });

        return result;
      } catch (err) {}
    }
    return [];
  },

  feed: async function(
    query?: string = '',
    exclusions?: Array<ExclusionCode> = [],
    languages?: Array<LanguageCode> = BROWSER_LANGUAGES,
    limit?: number = 97,
    lastStoryID?: ?string,
    skipReadStories?: boolean = true,
  ): Promise<FeedResult> {
    const thisUrl = new URL(window.location.href);

    for (var i = 0; i < FEED_ENDPOINTS.length; ++i) {
      const endPoint = FEED_ENDPOINTS[i];
      const url = new URL(endPoint);

      url.searchParams.append('q', query);
      url.searchParams.append('excl', exclusions.join(','));
      url.searchParams.append('lang', languages.join(','));
      url.searchParams.append('n', limit.toString());
      if (lastStoryID) {
        url.searchParams.append('last', lastStoryID);
      }
      url.searchParams.append('user', getUser(thisUrl));
      url.searchParams.append('all', Number(!skipReadStories).toString());

      try {
        const response = await fetch(url.toString());
        if (response.status != 200) {
          // console.log("Failed to fetch from ", url);
          continue;
        }
        const json = await response.json();

        return json;
      } catch (er) {}
    }
    return EMPTY_FEED_JSON;
  },

  action: async function(postData: string): Promise<void> {
    const thisUrl = new URL(window.location.href);
    const url = new URL(ACTION_ENDPOINT);
    url.searchParams.append('user', getUser(thisUrl));

    var header = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'feedback=' + postData,
    };

    // console.log('POSTing data: ' + JSON.stringify(header));

    await fetch(url.toString(), header).then((response) => {
      if (response.status != 200) {
        throw 'Got not OK response code: ' +
          response.status +
          ' from fetching url: ' +
          url.toString();
      }
    });
  },
};

export default API;
